<template>
  <div style="height: 100%;">
    <div class="orderTop" style="display: flex;">
      <div style="flex: 1;">
        <el-form :inline="true" size="mini">
          <el-form-item label="类型：">
            <el-select
              clearable
              placeholder="请选择类型"
              v-model="ChooseOrderType"
            >
              <el-option
                v-for="select in orderTypeId"
                :key="select.id"
                :value="select.id"
                :label="select.name"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="订单号：">
            <el-input v-model="orderNumber"></el-input>
          </el-form-item>

          <el-form-item label="手机号：">
            <el-input clearable v-model="mobile"></el-input>
          </el-form-item>
          <el-form-item label="时间：">
            <el-date-picker
              v-model="times"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="dateChange"
            >
            </el-date-picker>
          </el-form-item>
          <br />
          <el-form-item label="状态：">
            <div class="btn">
              <el-button
                v-for="(item, index) in btnTab"
                :key="index"
                @click="btnClick(item.type, item)"
                :class="{ active: btnCurrentIndex == item.type }"
                size="mini"
                type="primary"
                >{{ item.name }}</el-button
              >
            </div></el-form-item
          >
        </el-form>
      </div>
      <div
        style="width: 130px;display: flex;justify-content: space-between;height: 28px;"
      >
        <download-excel
          class="export-excel-wrapper"
          :data="tableData"
          :fields="json_fields"
          name="订单数据.xls"
        >
          <el-button size="mini" type="primary">导出</el-button>
        </download-excel>
        <div>
          <el-button size="mini" type="primary" @click="query">查询</el-button>
        </div>
      </div>
    </div>

    <el-tabs type="border-card">
      <el-tab-pane>
        <div class="tablecontaner">
          <div class="tableList">
            <el-table
              v-loading="showLoading"
              height="100%"
              :data="tableData"
              :row-key="tableRowKey"
              tooltip-effect="dark"
              :expand-row-keys="expandRowKeys"
              :row-style="{ height: '42px' }"
              :cell-style="{ padding: '0px' }"
              :header-row-style="{ height: '20px' }"
            >
              <el-table-column type="expand" width="1">
                <template slot-scope="props">
                  <div
                    :class="{ activeDetail: showText }"
                    v-loading="loading"
                    style="min-height: 100px"
                  >
                    <div v-show="!showText">
                      <div
                        element-loading-text="拼命加载中"
                        class="detail"
                        v-for="(item2, index2) in orderDetail"
                        :key="item2.row_id"
                      >
                        <div class="detailInfo">
                          <div>
                            <span
                              >{{
                                item2.type == 0
                                  ? "门票名称"
                                  : item2.type == 1
                                  ? "房间类型"
                                  : item2.type == 2
                                  ? "商品名称"
                                  : ""
                              }}：</span
                            >{{ item2.goods_name }}
                          </div>
                          <div>
                            <span>下单时间：</span>{{ item2.create_time }}
                          </div>
                          <div>
                            <span
                              >{{
                                item2.type == 0
                                  ? "门票"
                                  : item2.type == 1
                                  ? "房间"
                                  : item2.type == 2
                                  ? "商品"
                                  : ""
                              }}数量：</span
                            >{{ item2.quantity }}张
                          </div>
                          <div>
                            <span
                              >{{
                                item2.type == 0
                                  ? "门票"
                                  : item2.type == 1
                                  ? "房间"
                                  : item2.type == 2
                                  ? "商品"
                                  : ""
                              }}单价：</span
                            >￥{{ props.row.price }}
                          </div>
                          <div>
                            <span>总价：</span>￥{{ item2.total_price }}
                          </div>
                          <div>
                            <span>优惠价：</span>￥{{ props.row.dprice }}
                          </div>
                          <div>
                            <span>支付类型：</span
                            >{{ payTypes(props.row.pay_type) }}
                          </div>
                          <div>
                            <span>支付号：</span>{{ item2.order_number }}
                          </div>
                        </div>
                        <div
                          class="tripInfo"
                          v-for="item3 in orderContact"
                          :key="item3.row_id"
                        >
                          <p>
                            <span>
                              {{
                                item2.type == 0
                                  ? "出行人"
                                  : item2.type == 1
                                  ? "入住人"
                                  : item2.type == 2
                                  ? "联系人"
                                  : ""
                              }}
                            </span>
                            {{ ++index2 }}
                          </p>
                          <p>
                            <span><span>姓名：</span>{{ item3.name }}</span>
                            <span><span>手机号：</span>{{ item3.mobile }}</span>
                            <span v-if="item2.type !== 2"
                              ><span>身份证：</span>{{ item3.identity }}</span
                            >
                            <span v-else
                              ><span>地址：</span>{{ item3.address }}</span
                            >
                          </p>
                        </div>

                        <div v-show="props.row.status == 1 && item2.type == 2">
                          <el-form class="express" :model="express">
                            <el-form-item label="快递公司">
                              <el-input
                                clearable
                                size="mini"
                                v-model="express.express_name"
                              ></el-input>
                            </el-form-item>
                            <el-form-item label="快递单号">
                              <el-input
                                clearable
                                size="mini"
                                v-model="express.express_number"
                              ></el-input>
                            </el-form-item>
                            <el-form-item>
                              <el-button
                                plain
                                size="mini"
                                type="primary"
                                @click="onSubmit(props.row.row_id)"
                                >确认发货</el-button
                              >
                            </el-form-item>
                          </el-form>
                        </div>
                        <div v-show="props.row.status == 7 && item2.type == 2">
                          <el-form class="express">
                            <el-form-item label="快递公司">
                              <el-input
                                clearable
                                size="mini"
                                disabled
                                :value="orderContact[0].express_name"
                              ></el-input>
                            </el-form-item>
                            <el-form-item label="快递单号">
                              <el-input
                                clearable
                                size="mini"
                                disabled
                                :value="orderContact[0].express_number"
                              ></el-input>
                            </el-form-item>
                          </el-form>
                        </div>
                      </div>
                    </div>
                    <div class="no_information" v-show="showText">暂无数据</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="order_number"
                label="订单号"
                min-width="170"
              >
                <template slot-scope="scope">
                  <div class="order_number">
                    <el-link @click="rowClick(scope.row)">
                      {{ scope.row.order_number }}
                    </el-link>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                min-width="120"
                show-overflow-tooltip
                prop="order_name"
                label="订单名称"
              >
              </el-table-column>
              <el-table-column
                prop="mobile"
                label="手机号"
                min-width="110"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="type"
                label="订单类型"
                width="100"
                align="center"
              >
                <template slot-scope="scope">
                  {{ orderType(scope.row.type) }}
                </template>
              </el-table-column>
              <el-table-column label="订单状态" align="center" min-width="90">
                <template slot-scope="scope">
                  <el-button
                    v-show="scope.row.status || scope.row.status == 0"
                    type="button"
                    :class="className(scope.row.status)"
                    size="mini"
                  >
                    {{ statusName(scope.row.status) }}
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column label="总价" align="center">
                <template slot-scope="scope">
                  <span class="price">¥{{ scope.row.price }} </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="create_time"
                label="日期"
                min-width="170"
                align="center"
              >
              </el-table-column>
              <el-table-column label="操作" align="center" min-width="170">
                <template slot-scope="scope">
                  <el-button
                    style="margin-right: 10px"
                    size="mini"
                    @click="rowClick(scope.row)"
                    >查看详情</el-button
                  >
                  <el-popconfirm
                    @confirm="del(scope.row)"
                    title="确认删除这条数据吗"
                  >
                    <el-button size="mini" type="danger" slot="reference"
                      >删除</el-button
                    >
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="footer">
            <div>
              <el-pagination
                background
                :page-size="pageNum"
                :page-sizes="[20, 30, 40, 50, 100]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pageTotal"
                :current-page.sync="pageNo"
                @current-change="
                  (val) => {
                    handlePageChange(val);
                  }
                "
                @size-change="
                  (val) => {
                    handleSizeChange(val);
                  }
                "
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  components: {},
  name: "order",
  data() {
    return {
      tabitem: [
        {
          name: "sjy",
          lalel: "",
        },
      ],
      currentNav: {},
      tableData: [],
      pageNo: 1,
      pageNum: 20,
      pageTotal: 0,
      btnTab: [
        {
          name: "所有记录",
          type: "所有记录",
        },
        {
          name: "已发货",
          type: 7,
        },
        {
          name: "待退款",
          type: 3,
        },
        {
          name: "已预订",
          type: 2,
        },
        {
          name: "已支付",
          type: 1,
        },
        {
          name: "未支付",
          type: 0,
        },
      ],
      btnCurrentIndex: "所有记录",
      value: "",
      orderTypeId: [
        {
          id: 0,
          name: "门票",
        },
        {
          id: 1,
          name: "酒店",
        },
        {
          id: 2,
          name: "特色商品",
        },
      ],
      orderNumber: "", //订单号
      mobile: "", //手机号
      ChooseOrderType: "", //选择订单类型
      times: "",
      orderDetail: [
        //订单详情默认值
        {
          goods_name: "xxx",
        },
      ],
      orderContact: [
        //订单联系人默认值
        {
          name: "xxx",
        },
      ], //订单联系人
      loading: false, //加载
      showLoading: false,
      date_start: "",
      date_end: "",
      showText: false,
      loadings: false,
      currentData: { status: "" },
      tableRowKey: "row_id",
      expandRowKeys: [],
      json_fields: {
        订单号: {
          field: "order_number",
          callback: (value) => {
            return "&nbsp;" + value;
          },
        },
        订单名称: "order_name",
        手机号: "mobile",
        订单类型: {
          field: "type",
          callback: (value) => {
            return value == 0 ? "门票" : value == 1 ? "酒店" : "特色商品";
          },
        },
        订单状态: {
          field: "status",
          callback: (value) => {
            return value == 0
              ? "未支付"
              : value == 1
              ? "已支付"
              : value == 2
              ? "已预订"
              : value == 3
              ? "待退款"
              : value == 4
              ? "已退款"
              : value == 5
              ? "已核销"
              : value == 6
              ? "已检票"
              : value == 7
              ? "已发货"
              : value == 8
              ? "已收货"
              : value == 9
              ? "已评价"
              : "";
          },
        },
        总价: "price",
        日期: "create_time",
      },
      express: {
        express_name: "",
        express_number: "",
      },
    };
  },
  computed: {
    statusName() {
      let statusList = [
        "未支付",
        "已支付",
        "已预订",
        "待退款",
        "已退款",
        "已核销",
        "已检票",
        "已发货",
        "已收货",
        "已评价",
      ];
      return function(status) {
        return statusList[status];
      };
    },
    orderType() {
      let typeList = ["门票", "酒店", "特色商品"];
      return function(type) {
        return typeList[type];
      };
    },
    payTypes() {
      let payList = ["微信", "支付宝"];
      return function(pay_type) {
        return payList[pay_type];
      };
    },
    className() {
      let classList = ["class0", "class1", "class2", "class3", "class4"];
      return function(status) {
        if (status == "7") {
          return "class4";
        } else {
          return classList[status];
        }
      };
    },
  },
  watch: {
    times: {
      deep: true,
      handler: function(nv) {
        if (nv == null) {
          this.date_start = "";
          this.date_end = "";
        }
      },
    },
  },
  mounted() {
    this.currentNav = this.$store.state.currentNav;
    this.$set(this.tabitem[0], "label", this.currentNav.name);
    this.queryDataOfResource();
  },
  methods: {
    // 发货提交按钮
    onSubmit(row_id) {
      let params = {
        row_id: row_id,
        data: this.express,
      };
      this.$Request({
        method: "get",
        url: this.$Api.modifyexpress,
        params,
      })
        .then((result) => {
          this.$message({
            showClose: true,
            message: result.errMsg,
            type: "success",
          });
          this.queryDataOfResource();
        })
        .catch((err) => {});
    },
    // 删除订单
    del(row) {
      let params = {
        data: { row_id: row.row_id },
        directory_code: this.currentNav.index,
      };
      this.$Request({
        method: "get",
        url: this.$Api.deleteDataOfResource,
        params,
      })
        .then((result) => {
          if (result.errCode == 0) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.queryDataOfResource();
          }
        })
        .catch((err) => {});
    },
    //获取表格数据
    queryDataOfResource(data) {
      if (!this.showLoading) {
        this.showLoading = true;
      }
      let params = {
        directory_code: this.currentNav.index, //传值的关键,
        pageNo: this.pageNo,
        pageNum: this.pageNum,
        data: data,
      };

      this.$Request({
        method: "get",
        url: this.$Api.queryDataOfResource,
        params,
      })
        .then((result) => {
          this.tableData = result.data;
          this.pageTotal = result.total;
          this.showLoading = false;
        })
        .catch((err) => {});
    },
    //
    btnClick(index, item) {
      this.btnCurrentIndex = index;
      if (index == "所有记录") {
        // 单独查询
        this.currentData.status = "";
        this.queryDataOfResource(this.currentData); //查询全部数据
      } else {
        // 查询一起
        // let data = {
        //   status: index + "",
        //   // is_check: 1,
        //   type: this.ChooseOrderType,
        //   mobile: this.currentData.mobile,
        //   order_number: this.currentData.order_number,
        //   date_start: this.date_start,
        //   date_end: this.date_end,
        //   date_filed: "create_time",
        // };
        // Object.keys(data).forEach((key) => {
        //   if (data[key] === "") delete data[key];
        // });

        // this.currentData = data;
        // 单独查询
        this.currentData = {
          status: index + "",
        };
        this.queryDataOfResource(this.currentData);
      }
    },
    //分页
    handlePageChange(val) {
      this.pageNo = val;
      if (this.btnCurrentIndex == 5) {
        this.queryDataOfResource(this.currentData);
      } else {
        this.queryDataOfResource(this.currentData);
      }
    },
    //每页大小
    handleSizeChange(val) {
      this.pageNum = val;
      this.queryDataOfResource();
    },

    // 点击查看详情
    rowClick(row) {
      this.orderDetail = [];
      this.orderContact = [];
      this.expandCurRow(row);
      this.orderClick(row.order_number);
    },
    // 点击展开/收缩当前行
    expandCurRow(row) {
      if (this.expandRowKeys.length) {
        // 点击已展开的行 就要收起
        if (this.expandRowKeys.indexOf(row[this.tableRowKey]) >= 0) {
          this.expandRowKeys = [];
          return;
        }
      }
      this.expandRowKeys = [row[this.tableRowKey]];
    },
    //查询数据
    query() {
      let data = {
        type: this.ChooseOrderType,
        date_filed: "create_time",
        date_start: this.date_start,
        date_end: this.date_end,
        order_number: this.orderNumber,
        mobile: this.mobile,
        status: this.currentData.status,
      };
      //查询条件为空删除
      Object.keys(data).forEach((key) => {
        if (data[key] === "") delete data[key];
      });
      this.currentData = data;
      this.queryDataOfResource(this.currentData);
    },

    //点击订单详情
    orderClick(item) {
      if (!this.loading) {
        this.loading = true;
      }
      let params = {
        data: { order_number: item },
        directory_code: "orderdetail",
      };
      let params1 = {
        data: { order_number: item },
        directory_code: "ordercontact",
      };
      let p1 = this.$Request({
        method: "get",
        url: this.$Api.queryDataOfResource,
        params,
      });

      let p2 = this.$Request({
        method: "get",
        url: this.$Api.queryDataOfResource,
        params: params1,
      });

      Promise.all([p1, p2]).then((res) => {
        this.orderDetail = res[0].data;
        this.orderContact = res[1].data;
        this.loading = false;
        let execute = true;
        res.forEach((item) => {
          if (execute) {
            if (JSON.stringify(item.data) === "[]") {
              //判断数据是否为空
              this.showText = true;
              execute = false;
            } else {
              this.showText = false;
            }
          }
        });
      });
    },
    dateChange(item) {
      this.date_start = item[0] + " 00:00:00";
      this.date_end = item[1] + " 23:59:59";
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-form-item {
  margin-bottom: 8px !important;
}

/deep/.el-tabs__header {
  display: none;
}
/deep/.el-tabs__content {
  padding: 0 !important;
  padding-left: 15px !important;
}
/deep/.el-tab-pane {
  padding-right: 15px !important;
}
.tablecontaner {
  // 50+18+81
  height: calc(100vh - 169px);
  // box-shadow: 0px 0px 10px #d8d5d5;
}
.el-tabs--border-card {
  height: calc(100vh - 169px) !important;
}
/deep/.el-table {
  font-size: 12px;
}
.el-link {
  font-size: 12px !important;
}

.el-table .cell.el-tooltip {
  font-size: 12px !important;
}

.footer {
  display: flex;
  margin-top: 12px;
}
.footer > div {
  flex: 1;
  text-align: center;
}

.el-tooltip__popper {
  max-width: 30%;
  padding-bottom: 5px !important;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 15;
  /* -webkit-box-orient: vertical; */
}
.el-tooltip__popper,
.el-tooltip__popper.is-dark {
  /* background: rgb(48, 65, 86) !important; */
  color: #fff !important;
  /* line-height: 24px; */
}

.el-table__fixed-right-patch {
  width: 0 !important;
}
// 顶部检索收缩框宽度缩小
@media screen and (max-width: 1660px) {
  /deep/.el-range-separator {
    min-width: 16px;
  }
  /deep/.el-date-editor.el-input__inner {
    width: 210px;
  }
  /deep/.el-form--inline .el-input {
    width: 150px;
  }
  /deep/.el-form-item {
    margin-right: 5px;
  }
}
@media screen and (max-width: 1261px) {
  .tablecontaner {
    // 50+18+137
    height: calc(100vh - 205px);
    // box-shadow: 0px 0px 10px #d8d5d5;
  }
  .el-tabs--border-card {
    height: calc(100vh - 205px) !important;
  }
}
/deep/.el-table__expanded-cell {
  padding: 0 !important;
}

.tripInfo {
  padding: 10px 50px;
  width: 100% !important;
  border-top: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.tripInfo > p:nth-child(1) {
  color: #888888;
}

.tripInfo > p:nth-child(2) {
  display: flex;
}

.tripInfo > p:nth-child(2) > span {
  // margin-right: 11%;
  // width: 20px;
  flex: 0 1 200px;
  display: inline-block;
}

.tripInfo > p:nth-child(2) > span > span {
  color: #888888;
}

.active {
  background-color: #1876f9 !important;
  color: #fff !important;
}

.detailInfo {
  // margin-left: 60px;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 50px;
  row-gap: 8px;
}

.detailInfo > div {
  width: 35%;
  // margin-top: 15px;
}

.detailInfo > div > span {
  display: inline-block;
  width: 70px;
  text-align: justify;
  text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
  text-align-last: justify;
  color: #888888;
}

.no_information{
  display: flex;
  min-height: 100px;
  justify-content: center;
  align-items: center;
}

.orderTop {
  padding: 9px 15px;
  // height: 130px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 9px;
  // padding-bottom: 30px;
  // padding-left: 15px;
  // border: 1px solid #f0eeee;
  box-shadow: 0px 0px 10px #d8d5d5;
}

.btn .el-button {
  // margin-left: 10px;
  color: #3386fa;
  background-color: #e1eefe;
  border: 1px solid #1876f9;
}

.class0 {
  color: #f56c6c;
  background-color: #fde2e2;
  border: 1px solid #f56c6c;
}
.class1 {
  color: #909399;
  background-color: #e9e9eb;
  border: 1px solid #909399;
}
.class2 {
  color: #409eff;
  background-color: #d9ecff;
  border: 1px solid #409eff;
}
.class3 {
  color: #e6a23c;
  background-color: #faecd8;
  border: 1px solid #e6a23c;
}
.class4 {
  color: #67c23a;
  background-color: #e1f3d8;
  border: 1px solid #67c23a;
}

.tableList {
  height: calc(100% - 56px);
}

/deep/.el-icon-arrow-right {
  display: none;
}

.price {
  color: #ff5722;
}

/**修改全局的滚动条*/
/**滚动条的宽度*/
::-webkit-scrollbar {
  width: 3px;
  //padding-left: 10px;
}

//滚动条的滑块
::-webkit-scrollbar-thumb {
  background-color: #fafbfc;
  border-radius: 3px;
}
</style>
